import React, { useEffect } from "react";
import { graphql } from "gatsby";

// components
import { SectionContent, Cards, CollectionsLinks, Seo } from "../../components";
import Layout from "../../components/Layout";

const SoldOut = ({ data }) => {
  const books = data.soldout.nodes;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [data]);

  return (
    <Layout>
      <Seo title="Sold Out" />
      <SectionContent>
        <CollectionsLinks />
        <Cards cards={books} title="Sold Out" />
      </SectionContent>
    </Layout>
  );
};

export const query = graphql`
  query GetSoldOut {
    soldout: allAirtable(
      filter: {
        data: { categories: { eq: "Sold Out" }, status: { eq: "Available" } }
      }
      sort: { fields: data___Release_Date, order: DESC }
    ) {
      nodes {
        data {
          ...CardParts
        }
      }
    }
  }
`;

export default SoldOut;
